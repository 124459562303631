import _ from 'lodash';
import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
// Material-UI styles
import { makeStyles } from '@material-ui/styles';
// React Hook Form
import { Controller, useFormContext } from 'react-hook-form';
// React Select components and styles
import Select, { components, StylesConfig } from 'react-select';

// MUI components and utilities
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ClearIcon from '@mui/icons-material/Clear';

type Props = {
  name: string;
  label?: string;
  helperText?: string;
  type?: string;
  isMulti?: boolean;
  isAsync?: boolean;
  fetchHandler?: any;
  options?: { value: string; label: string }[];
  onChange?: (value: any) => void;
  value?: any;
  defaultOptions?: { value: string; label: string }[];
  maxInputLength?: number; // Added maxInputLength prop
  disabled?: boolean;
  onFocus?:any;
};

type OptionType = {
  value: string;
  label: string;
};

const ReactAsyncSelect = ({
  name,
  label,
  helperText,
  type,
  isMulti,
  isAsync,
  fetchHandler,
  options,
  onChange,
  onFocus,
  value,
  defaultOptions,
  maxInputLength = 50,
  disabled,
  ...other
}: Props) => {
  const theme = useTheme();
  const classes = useStyles();
  const [focused, setFocused] = useState(false);
  const { control } = useFormContext();

  const handleFocus = () => {
    setFocused(true);
    if (onFocus) {
      onFocus();
    }
  };

  const handleBlur = () => {
    setFocused(false);
  };


  const selectStyles: StylesConfig = {
    control: (styles) => ({
      ...styles,
      border: '0 !important',
      boxShadow: '0 !important',
      '&:hover': {
        border: '0 !important',
      },
      backgroundColor: 'transparent',
    }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: '#ebeef8',
      borderRadius: 5,
      zIndex: 100,
      justifyContent: 'center',
      alignItems: 'center',
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: '#002EA7',
      fontWeight: '500',
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      borderRadius: 15,
      height: 15,
      width: 15,
      backgroundColor: '#7a92d1',
      margin: 6,
      color: 'white',
      ':hover': {
        backgroundColor: 'white',
        color: '#7a92d1',
      },
    }),
    menuPortal: (styles) => ({
      ...styles,
      zIndex: 9999,
    }),
    option: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: isFocused ? '#ebeef8' : undefined,
      fontSize: 14,
      borderRadius: 4,
      color: theme.palette.grey[700],
      zIndex: 100,
    }),
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadData = React.useCallback(
    _.debounce((inputText, callback) => {
      console.log('Input Text:', inputText);
      fetchHandler(inputText)
        .then((fetchedOptions: OptionType) => {
          console.log('Options:', fetchedOptions);
          callback(fetchedOptions);
        })
        .catch((error: any) => {
          console.error('Fetch Error:', error);
          callback([]);
        });
    }, 300),
    [fetchHandler]
  );

  const ClearIndicator = (props: any) => (
    <components.ClearIndicator {...props}>
      <ClearIcon style={{ cursor: 'pointer' }} />
    </components.ClearIndicator>
  );

  const handleInputChange = (inputValue: string) => {
    if (inputValue.length > maxInputLength) {
      return inputValue.slice(0, maxInputLength); // Truncate input to maxInputLength
    }
    return inputValue;
  };

  return (
    <Controller
      name={name || 'Name'}
      control={control}
      render={({ field, fieldState: { error } }: any) => (
        <div className={classes.container}>
          <div className={classes.materialTextField}>
            {isAsync ? (
              <AsyncSelect
                components={{
                  IndicatorSeparator: () => null,
                  ClearIndicator,
                }}
                isDisabled={disabled}
                menuPortalTarget={document.body}
                loadOptions={loadData}
                isMulti={isMulti}
                defaultOptions={defaultOptions}
                className={classes.input}
                onFocus={handleFocus}  // Attach the handleFocus to onFocus
                onBlur={handleBlur}
                onChange={(option) => {
                  field.onChange(option);
                }}
                onInputChange={handleInputChange}
                placeholder=" "
                value={field.value}
                styles={selectStyles}
                isClearable
              />
            ) : (
              <Select
                components={{
                  IndicatorSeparator: () => null,
                  ClearIndicator,
                }}
                isMulti={isMulti}
                options={defaultOptions || options}
                className={classes.input}
                onFocus={handleFocus}  // Attach the handleFocus to onFocus
                onBlur={() => field.value === '' && handleBlur()}
                onChange={(option) => {
                  field.onChange(option);
                }}
                onInputChange={handleInputChange}
                placeholder=" "
                value={field.value}
                styles={selectStyles}
                isClearable
                menuPortalTarget={document.body}
              />
            )}
            <label
              className={`${classes.label} ${
                focused || field.value !== '' ? classes.labelFocused : ''
              }`}
              htmlFor={name}
            >
              {label}
            </label>
          </div>
          {!!error && (
            <Typography mt={1} color={theme.palette.error.main} variant="caption">
              {error.message || (error.label && (error.label as any)?.message)}
            </Typography>
          )}
        </div>
      )}
    />
  );
};


const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  materialTextField: {
    position: 'relative',
    width: '100%',
  },
  label: {
    position: 'absolute',
    fontSize: '0.9rem',
    left: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: 'white',
    padding: '0 0.3rem',
    margin: '0 0.5rem',
    transition: '0.1s ease-out',
    transformOrigin: 'left top',
    pointerEvents: 'none',
    color: '#95a1ae',
    zIndex: 0,
  },
  input: {
    fontSize: '14px',
    outline: 'none',
    border: '1px solid #e9ecee',
    borderRadius: '5px',
    padding: '0.4rem 0.7rem',
    transition: '0.1s ease-out',
  },
  labelFocused: {
    top: 0,
    transform: 'translateY(-50%) scale(0.9)',
    fontSize: '0.9rem',
    fontWeight: 'bold',
  },
}));

export default ReactAsyncSelect;
