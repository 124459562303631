import { FC, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import { Box, Fade, Modal, Button, IconButton } from '@mui/material';

import Iconify from '../iconify/iconify';

interface ImageModalProps {
  open: boolean;
  handleClose: () => void;
  image: any;
}

const ImageViewer: FC<ImageModalProps> = ({ open, handleClose, image }) => {
  const classes = styles();
  const [rotation, setRotation] = useState(0);

  const handleRotateImage = () => {
    setRotation((prev) => prev + 90);
  };

  return (
    <Modal className={classes.modal} open={open} onClose={handleClose} closeAfterTransition>
      <div className={classes.imagePreview}>
        <IconButton onClick={handleClose} className={classes.closeButton}>
          <Iconify icon="eva:close-fill" />
        </IconButton>
        <TransformWrapper
          initialScale={0.4}
          minScale={0.3}
          centerOnInit
          centerZoomedOut
          maxScale={4}
        >
          {({ zoomIn, zoomOut }) => (
            <>
              <div className={classes.zoomControls}>
                <Button variant="contained" onClick={() => zoomIn()}className={classes.zoomButton}>
                  Zoom in +
                </Button>
                <Button variant="contained"  onClick={() => zoomOut()} className={classes.zoomButton}>
                  Zoom out -
                </Button>
                <Button variant="contained" onClick={handleRotateImage} className={classes.zoomButton}>
                  Rotate ⟳
                </Button>
              </div>
              <TransformComponent>
                <Fade in={open} timeout={500}>
                  <Box
                    component="img"
                    src={typeof image === 'string' ? image : URL.createObjectURL(image)}
                    alt="Uploaded"
                    className={classes.imageStyle}
                    sx={{
                      transform: `rotate(${rotation}deg)`,
                    }}
                  />
                </Fade>
              </TransformComponent>
            </>
          )}
        </TransformWrapper>
      </div>
    </Modal>
  );
};

const styles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    
  },
  imagePreview: {
    position: 'relative',
    width: '80vw',  
    height: '80vh', 
   
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  imageStyle: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 5,
    backgroundColor: 'red',
    color: 'white',
    zIndex: 1000,
    '&:hover': {
      backgroundColor: 'darkred',
    },
  },
  zoomControls: {
    position: 'fixed',
    bottom: 30,
    left: '50%',
    transform: 'translateX(-50%)',
    display: 'flex',
    gap: '10px',
    zIndex: 1000,
  },
  zoomButton: {
    width: '110px',
    height: '40px',
    fontSize: '1rem',
    backgroundColor: '#d3d3d3',
    color: '#000',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
});

export default ImageViewer;
