import { Helmet } from 'react-helmet-async';

function PageTitle({ title }: { title: string }) {
  return (
    <Helmet>
      <title>White Gold - {title}</title>
    </Helmet>
  );
}

export default PageTitle;
