import { useDispatch } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

import bidSlice from './slices/bid-slice';
import userSlice from './slices/user-slice';
import leadReducer from './slices/lead-slice';
import alertsSlice from './slices/alerts-slice';
import salesSlices from './slices/sales-slices';
import ordersSlice from './slices/orders-slice';
import regionsSlice from './slices/regions-slice';
import marketsSlice from './slices/markets-slice';
import meltingSlice from './slices/meltings-slice';
import paymentsSlice from './slices/payments-slice';
import notificationsSlice from './notifications-slice';
import transactionSlice from './slices/transaction-slice';

export const store = configureStore({
  reducer: {
    user: userSlice,
    regions: regionsSlice,
    transactions: transactionSlice,
    alerts: alertsSlice,
    notifications: notificationsSlice,
    sales: salesSlices,
    telesales: leadReducer,
    payments: paymentsSlice,
    order: ordersSlice,
    bids: bidSlice,
    melting: meltingSlice,
    markets: marketsSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export type RootState = ReturnType<typeof store.getState>;
