// @ts-nocheck
/* eslint-disable */

import * as XLSX from 'xlsx';
import { jsPDF as JsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

const styles = {
  pdf: {
    title: {
      fontSize: 16,
      textColor: [40, 40, 40], // Dark grey
      align: 'center',
    },
    groupHeader: {
      fillColor: [30, 144, 255], // Blue
      textColor: [255, 255, 255], // White
      fontStyle: 'bold',
      halign: 'center',
    },
    emptyGroupHeader: {
      fillColor: [200, 200, 200], // Light grey
      textColor: [0, 0, 0], // Black
    },
    columnHeader: {
      fillColor: [0, 46, 167], // to #002EA7
      textColor: [255, 255, 255], // White
      fontStyle: 'bold',
      fontSize: 10,
      halign: 'center',
    },
    body: {
      font: 'helvetica',
      fontSize: 9,
      cellPadding: 3,
      fillColor: [255, 255, 255], // White for body rows
      textColor: [0, 0, 0], // Black for text
    },
    alternateRow: {
      fillColor: [245, 245, 245], // Light grey for alternate rows
    },
  },
  excel: {
    groupHeaderFillColor: '#ADD8E6', // Light Blue for Excel Group Headers
  },
};

/**
 * Utility function to export table data to PDF.
 * @param columns - Array of column definitions with headers.
 * @param records - Array of row data that matches column accessors.
 * @param fileName - The name of the generated PDF file.
 */
export const exportToPDF = (fields: any[], records: any[], fileName: string) => {
  const orientation = 'landscape';
  const doc = new JsPDF({ orientation });

  // Declare tableHeaders before usage
  const tableHeaders: string[] = [];

  // Prepare group headers and field headers
  const groupHeaders: any[] = [];
  fields.forEach((field) => {
    if (field.group) {
      if (
        groupHeaders.length === 0 ||
        groupHeaders[groupHeaders.length - 1]?.content !== field.group
      ) {
        groupHeaders.push({
          content: field.group,
          colSpan: 1,
          styles: styles.pdf.groupHeader,
        });
      } else {
        groupHeaders[groupHeaders.length - 1].colSpan += 1;
      }
    } else {
      groupHeaders.push({
        content: '',
        colSpan: 1,
        styles: styles.pdf.emptyGroupHeader,
      });
    }
    tableHeaders.push(field.header); // Add the column header
  });

  // Prepare headers from the unified fields
  // Exclude "Actions" header
  const filteredHeaders = fields
    .filter((field) => field.header !== 'Actions')
    .map((field) => field.header);

  // Prepare table data from the unified fields
  const tableData = records.map((row) =>
    fields.map((field) => row[field.key || field.accessorKey] || '')
  );

  // Add the table to the PDF document
  autoTable(doc, {
    head: [groupHeaders, filteredHeaders],
    body: tableData,
    theme: 'grid',
    headStyles: styles.pdf.columnHeader,
    alternateRowStyles: styles.pdf.alternateRow,
    styles: styles.pdf.body,
    startY: 20, // Starting position of the table
    margin: { top: 10, left: 10, right: 10 }, // Margins
    didDrawCell: (data) => {
      if (data.row.index === 0) {
        const cell = data.cell;
        if (cell.raw?.content === '') {
          cell.styles.fillColor = styles.pdf.emptyGroupHeader.fillColor; // Grey for empty cells in group headers
        }
      }
    },
  });

  // Add a title
  doc.setFontSize(styles.pdf.title.fontSize);
  doc.setTextColor(...styles.pdf.title.textColor);
  doc.text('Report', doc.internal.pageSize.getWidth() / 2, 10, { align: styles.pdf.title.align });

  // Save the PDF file
  doc.save(fileName);
};

/**
 * Utility function to export table data to Excel.
 * @param columns - Array of column definitions with headers.
 * @param records - Array of row data that matches column accessors.
 * @param fileName - The name of the generated Excel file.
 */
export const exportToExcel = (fields: any[], records: any[], fileName: string) => {
  // Filter out the "Actions" header
  const filteredFields = fields.filter((field) => field.header !== 'Actions');

  // Prepare the group headers and field headers
  const groupHeaders: string[] = [];
  const fieldHeaders: string[] = [];

  filteredFields.forEach((field) => {
    if (field.group) {
      groupHeaders.push(field.group); // Add group name
    } else {
      groupHeaders.push(''); // Leave empty for ungrouped fields
    }
    fieldHeaders.push(field.header); // Add the field label
  });

  // Prepare the data rows
  const tableData = records.map((row) =>
    filteredFields.map((field) => row[field.key || field.accessorKey] || '')
  );

  // Combine group headers, field headers, and table data
  const completeData = [groupHeaders, fieldHeaders, ...tableData];

  // Convert to a worksheet
  const ws = XLSX.utils.aoa_to_sheet(completeData);

  // Add merges for group headers
  const merges: XLSX.Range[] = [];
  let lastGroup = '';
  let startCol = 0;

  groupHeaders.forEach((group, index) => {
    if (group !== lastGroup) {
      if (lastGroup !== '' && startCol < index - 1) {
        merges.push({ s: { r: 0, c: startCol }, e: { r: 0, c: index - 1 } });
      }
      lastGroup = group;
      startCol = index;
    }
    if (index === groupHeaders.length - 1 && lastGroup !== '' && startCol <= index) {
      merges.push({ s: { r: 0, c: startCol }, e: { r: 0, c: index } });
    }
  });

  ws['!merges'] = merges;

  // Apply styling to group header rows
  const groupHeaderRow = ws[1]; // Group header is the first row
  if (groupHeaderRow) {
    Object.keys(groupHeaderRow).forEach((key) => {
      groupHeaderRow[key].s = { fill: { fgColor: { rgb: styles.excel.groupHeaderFillColor } } }; // Apply blue color
    });
  }

  // Create a workbook and append the worksheet
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Audit Report');

  // Ensuring the file name includes the `.xlsx` extension
  const validFileName = fileName.endsWith('.xlsx') ? fileName : `${fileName}.xlsx`;

  // Trigger the Excel file download
  XLSX.writeFile(wb, validFileName);
};