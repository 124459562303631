import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { updateOrder, getAllOrders, getOrdersCounts, getOrdersReport } from 'src/apis/orders';

interface VerificationState {
  loading: boolean;
  records: any[];
  currentPage: number;
  pageSize: number;
  totalRecords: number;
  currentTransaction: any;
  ordersCount: Record<string, number>; // Holds counts for different order statuses
  totalDifference?: number;
  totalGrossWeight?: number;
  totalBranchGrossWeight?: number;
}

const initialState: VerificationState = {
  loading: false,
  records: [],
  currentPage: 1,
  pageSize: 10,
  totalRecords: 0,
  currentTransaction: null,
  ordersCount: {}, // Initialize counts
  totalDifference: 0,
  totalGrossWeight: 0,
  totalBranchGrossWeight: 0,
};

// Fetch all orders
export const fetchAllOrders = createAsyncThunk(
  'orders/fetchAllOrders',
  async ({
    status,
    pageSize,
    page,
    startDate,
    endDate,
    searchText,
    transactionType,
  }: {
    status: string;
    pageSize?: number;
    page?: number;
    startDate?: string;
    endDate?: string;
    searchText?: string;
    transactionType?: string;
  }) => {
    const response = await getAllOrders({
      status,
      pageSize,
      page,
      startDate,
      endDate,
      searchText,
      transactionType,
    });
    return response;
  }
);

// Update order
export const updateOrderThunk = createAsyncThunk(
  'orders/updateOrder',
  async ({
    id,
    payload,
  }: {
    id: string;
    payload: { gross_weight: number; received: boolean; difference: number };
  }) => {
    const response = await updateOrder(id, payload);
    return response;
  }
);

// Fetch order counts
export const fetchOrdersCounts = createAsyncThunk('orders/fetchOrdersCounts', async () => {
  const response = await getOrdersCounts();
  return response;
});

// Fetch reports for orders
export const fetchOrdersReports = createAsyncThunk(
  'orders/fetchReports',
  async ({
    startDate,
    endDate,
    status,
  }: {
    startDate: string;
    endDate: string;
    status: string;
  }) => {
    const response = await getOrdersReport(startDate, endDate, status);
    return response;
  }
);

const ordersSlice = createSlice({
  name: 'verification',
  initialState,
  reducers: {
    setCurrentTransactions: (state, action) => {
      state.currentTransaction = action.payload;
    },
    resetRecords: (state) => {
      state.records = [];
      state.pageSize = 10;
      state.totalRecords = 0;
    },
  },
  extraReducers: (builder) => {
    // Handle fetchAllOrders
    builder.addCase(fetchAllOrders.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllOrders.fulfilled, (state, action) => {
      state.loading = false;
      state.records = action.payload.records || [];
      state.totalRecords = action.payload.total_records;
    });
    builder.addCase(fetchAllOrders.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateOrderThunk.fulfilled, (state, action) => {
      let updatedOrder = action.payload;

      // Adjust the 'received' field based on the status
      updatedOrder = {
        ...action.payload,
        received: action.payload.status === 'RECEIVED' ? 'Yes' : 'No',
      };

      const index = state.records.findIndex((record) => record.id === updatedOrder.id);

      if (index !== -1) {
        const existingRecord = state.records[index];
        state.records[index] = { ...existingRecord, ...updatedOrder };
      }

      // TODO : Fix the issue with the below code
    });

    // Handle fetchOrdersReports
    builder.addCase(fetchOrdersReports.pending, (state, action) => {
      state.totalDifference = 0;
      state.totalGrossWeight = 0;
      state.totalBranchGrossWeight = 0;
    });

    builder.addCase(fetchOrdersReports.fulfilled, (state, action) => {
      state.totalDifference = action.payload.difference?.toFixed(2);
      state.totalGrossWeight = action.payload.gross_weight?.toFixed(2);
      state.totalBranchGrossWeight = action.payload.branch_gross_weight?.toFixed(2);
    });

    // Handle fetchOrdersCounts
    builder.addCase(fetchOrdersCounts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchOrdersCounts.fulfilled, (state, action) => {
      state.loading = false;
      state.ordersCount = action.payload || {};
    });
    builder.addCase(fetchOrdersCounts.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { setCurrentTransactions, resetRecords } = ordersSlice.actions;
export default ordersSlice.reducer;
