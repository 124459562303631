/* eslint-disable */
import React from 'react';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TablePagination,
  Box,
} from '@mui/material';

// Interfaces
import { GenericTableProps, ColumnDefinition } from 'src/interfaces';

const GenericTable: React.FC<GenericTableProps> = ({
  rows,
  columns,
  totalRecords,
  page,
  pageSize,
  onPageChange,
  onRowsPerPageChange,
  footer,
  loading,
}) => {
  // Call `useStyles` unconditionally to comply with React Hook rules
  const classes = useStyles();

  if (!columns || !rows) {
    console.error('Columns or rows are undefined');
    return null;
  }

  // Helper function to handle cell rendering
  const renderCellContent = (column: ColumnDefinition, row: any, rowIndex: number) => {
    if (column.render) {
      return column.render(row, rowIndex);
    }
    return row[column.id] !== undefined ? row[column.id] : 'N/A';
  };

  // Group columns by their group property
  const groupedColumns = columns.reduce(
    (acc, column) => {
      if (column.group) {
        if (!acc.grouped[column.group]) {
          acc.grouped[column.group] = [];
        }
        acc.grouped[column.group].push(column);
      } else {
        acc.ungrouped.push(column);
      }
      return acc;
    },
    { ungrouped: [] as ColumnDefinition[], grouped: {} as { [key: string]: ColumnDefinition[] } }
  );

  const groupKeys = Object.keys(groupedColumns.grouped);

  const renderRows = (rows: any[], footer: any[] | undefined) => {
    const rowsCells = rows.map((row, rowIndex) => (
      <TableRow key={row.id || rowIndex} sx={classes.hoverRow}>
        {columns.map((column) => (
          <TableCell key={column.id} align="center" sx={classes.tableCell}>
            {renderCellContent(column, row, rowIndex)}
          </TableCell>
        ))}
      </TableRow>
    ));

    if (footer) {
      const footerRows = (
        <TableRow className="" key={'tableFooter'}>
          {footer.map((x, i) => (
            <TableCell key={i} align="center" sx={classes.tableCell}>
              {x}
            </TableCell>
          ))}
        </TableRow>
      );

      return [...rowsCells, footerRows];
    }

    return rowsCells;
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {groupedColumns.ungrouped.map((_, index) => (
                <TableCell
                  key={`ungrouped-placeholder-${index}`}
                  sx={classes.ungroupedPlaceholder}
                />
              ))}
              {groupKeys.map((group) => (
                <TableCell
                  key={group}
                  colSpan={groupedColumns.grouped[group].length}
                  align="center"
                  sx={classes.groupHeader}
                >
                  {group}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align="center" sx={classes.columnHeader}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={columns.length} sx={classes.loadingRow}>
                  Loading...
                </TableCell>
              </TableRow>
            ) : rows.length > 0 ? (
              renderRows(rows, footer)
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} sx={classes.loadingRow}>
                  No records found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={classes.paginationBox}>
        <TablePagination
          component="div"
          count={totalRecords}
          page={page}
          rowsPerPage={pageSize}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      </Box>
    </>
  );
};

// Refactored useStyles (moved outside conditional logic)
const useStyles = () => ({
  ungroupedPlaceholder: {
    minWidth: '150px',
    padding: '8px',
  },
  groupHeader: {
    fontWeight: 'bold',
    backgroundColor: '#f0f0f8',
    padding: '8px',
    borderLeft: '2px solid #e0e0e0',
    minWidth: '100px',
  },
  columnHeader: {
    fontWeight: 'bold',
    backgroundColor: '#f9f9fb',
    padding: '8px',
    borderBottom: '2px solid #e0e0e0',
    minWidth: '140px',
  },
  tableCell: {
    padding: '8px',
  },
  loadingRow: {
    padding: '16px',
    textAlign: 'center',
  },
  hoverRow: {
    '&:hover': { backgroundColor: '#f5f5f7' },
  },
  paginationBox: {
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

export default GenericTable;
