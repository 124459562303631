/* eslint-disable */
import * as Yup from 'yup';

export const BidderSchema = Yup.object().shape({
    bidder_id: Yup.object()
        .shape({
            label: Yup.string().required('Bidder is required'),
            value: Yup.string()
                .required('Bidder value is required')
        })
        .required('Bidder is required'),
    market_rate: Yup.number()
        .required('Gold rate is required')
        .min(0, 'Gold rate must be greater than zero')
        .max(99999, 'Gold rate must be at most 5 digits')
        .transform((value) => (isNaN(value) ? undefined : value)),
    weight: Yup.number()
        .required('Bid weight is required')
        .min(0, 'Bid weight must be greater than zero')
        .max(99999, 'Bid weight must be at most 5 digits')
        .transform((value) => (isNaN(value) ? undefined : value)),
    bid_date: Yup.date().required('Bid date is required'),
});
