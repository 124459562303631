// React
import React, { useState } from 'react';
import { format } from "date-fns";

// MUI components
import { Card } from '@mui/material';

// Components
import CustomBreadcrumbs from 'src/components/custom-breadcrumb/custom-breadcrumb';

import AllSummary from '../components/tables/melting/melting-summary';

const MeltingSummary: React.FC = () => {
  const [headerText, setHeaderText] = useState("Melting Summary");

  const handleEditDetails = (date: string) => {
    setHeaderText(`Melting Summary of ${date}`);
  };

  return (
    <div>
      <CustomBreadcrumbs
        heading={headerText}
        links={[{ name: 'Dashboard', href: '/dashboard/melting/summary' }, { name: 'Melting Summary' }]}
        sx={{ mb: { xs: 3 } }}
      />
      <Card sx={{ p: 1, pt: 5 }}>
        <AllSummary onEditDetails={handleEditDetails} />
      </Card>
    </div>
  );
};

export default MeltingSummary;
