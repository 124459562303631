import React, { useMemo, useState, useEffect, useCallback } from 'react';
import toast from 'react-hot-toast';

import { LoadingButton } from '@mui/lab';
import { FileDownloadOutlined } from '@mui/icons-material';
import { Stack, Divider, Typography } from '@mui/material';

// API Call
import { getMeltingAudit } from 'src/apis/meltings';
import { formatOnlyDate } from 'src/utils/date-utils';
import { formatNumber } from 'src/utils/purity-calculation';
import { exportToExcel, exportToPDF } from 'src/utils/export-file-utils';

// Custom Components
import FilterComponent from 'src/layouts/common/filter-search';
import TableSkeleton from 'src/components/table/table-skeleton';
import GenericTable from '../branch/tables/generic-table';

const MeltingReport: React.FC = () => {
    // State Variables
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [meltingData, setMeltingData] = useState<any[]>([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(false);

    // Fetch Melting Audit Data
    const fetchMeltingData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await getMeltingAudit(startDate, endDate, pageSize, page, searchQuery);
            if (response && response.record) {
                const records = response.record;

                // Process records to create table rows
                const processedData = records.map((record: any) => ({
                    transaction_date: formatOnlyDate(record.transaction?.created_at) || 'Unknown',
                    branch: record.transaction?.branch?.name || 'Unknown',
                    customer_id: `${record.transaction?.customer?.id || ''}`,
                    customer: `${record.transaction?.customer?.first_name || ''} ${record.transaction?.customer?.last_name || ''}`,
                    gross_weight: formatNumber(record.branch_gross_weight || 0),
                    net_weight: formatNumber(record.branch_net_weight || 0),
                    wastage: formatNumber(record.branch_wastage || 0),
                    stone_weight: formatNumber(record.branch_stone_weight || 0),
                    by_cmp_stone_removal: formatNumber(record.melting?.[0]?.stone_removal_weight || 0),
                    by_finance_stone_removal: formatNumber(record.melting?.[1]?.stone_removal_weight || 0),
                    stone_removal_variance: formatNumber(
                        (record.melting?.[1]?.stone_removal_weight || 0) -
                        (record.melting?.[0]?.stone_removal_weight || 0)
                    ),
                    by_cmp_melting_weight: formatNumber(record.melting?.[0]?.melting_weight || 0),
                    by_finance_melting_weight: formatNumber(record.melting?.[1]?.melting_weight || 0),
                    melting_weight_variance: formatNumber(
                        (record.melting?.[1]?.melting_weight || 0) -
                        (record.melting?.[0]?.melting_weight || 0)
                    ),
                    by_cmp_purity: formatNumber(record.melting?.[0]?.melting_purity || 0),
                    by_finance_purity: formatNumber(record.melting?.[1]?.melting_purity || 0),
                    purity_variance: formatNumber(
                        (record.melting?.[1]?.melting_purity || 0) -
                        (record.melting?.[0]?.melting_purity || 0)
                    ),
                    by_cmp_billing_purity: formatNumber(record.billing_purity || 0),
                    by_finance_billing_purity: formatNumber(record.billing_purity || 0),
                    billing_purity_variance: formatNumber(0),
                }));

                setMeltingData(processedData);
                setTotalRecords(response.total_records || 0);
            } else {
                setMeltingData([]);
                setTotalRecords(0);
            }
        } catch (error: any) {
            console.error('Error fetching melting data:', error);
            toast.error(error.message || 'Error fetching melting data');
        } finally {
            setLoading(false);
        }
    }, [startDate, endDate, page, pageSize, searchQuery]);

    useEffect(() => {
        fetchMeltingData();
    }, [fetchMeltingData]);

    // Helper function to determine the color based on variance
    const getColor = (variance: number) => {
        if (variance < 0) {
            return '#FF5630'; // Red for negative values
        }
        if (variance > 0) {
            return '#00A76F'; // Green for positive values
        }
        return 'inherit';
    };

    // Columns Definition
    const columns = useMemo(() => [
        { id: 'transaction_date', label: 'Transaction Date' },
        { id: 'branch', label: 'Branch' },
        { id: 'customer_id', label: 'Customer ID' },
        { id: 'customer', label: 'Customer Name' },
        { id: 'gross_weight', label: 'Gross Weight (Gms)' },
        { id: 'stone_weight', label: 'Stone Weight (Gms)' },
        { id: 'wastage', label: 'Wastage (Gms)' },
        { id: 'net_weight', label: 'Net Weight (Gms)' },
        {
            id: 'by_cmp_stone_removal',
            label: 'By GPM',
            group: 'Weight after Stone Removal (Gms)',
        },
        {
            id: 'by_finance_stone_removal',
            label: 'By Finance',
            group: 'Weight after Stone Removal (Gms)',
        },
        {
            id: 'stone_removal_variance',
            label: 'Variance',
            group: 'Weight after Stone Removal (Gms)',
            render: (row: any) => (
                <Typography style={{ color: getColor(row.stone_removal_variance) }}>
                    {row.stone_removal_variance}
                </Typography>
            ),
        },
        {
            id: 'by_cmp_melting_weight',
            label: 'By GPM',
            group: 'Weight after Melting (Gms)',
        },
        {
            id: 'by_finance_melting_weight',
            label: 'By Finance',
            group: 'Weight after Melting (Gms)',
        },
        {
            id: 'melting_weight_variance',
            label: 'Variance',
            group: 'Weight after Melting (Gms)',
            render: (row: any) => (
                <Typography style={{ color: getColor(row.melting_weight_variance) }}>
                    {row.melting_weight_variance}
                </Typography>
            ),
        },
        {
            id: 'by_cmp_purity',
            label: 'By GPM',
            group: 'After Melting Purity (%)',
        },
        {
            id: 'by_finance_purity',
            label: 'By Finance',
            group: 'After Melting Purity (%)',
        },
        {
            id: 'purity_variance',
            label: 'Variance',
            group: 'After Melting Purity (%)',
            render: (row: any) => (
                <Typography style={{ color: getColor(row.purity_variance) }}>
                    {row.purity_variance}
                </Typography>
            ),
        },
    ], []);

    // Export PDF
    const Actions = () => {
        const exportPDF = () => {
            const fields = columns.map((col) => ({ header: col.label, key: col.id, group: col.group }));
            exportToPDF(fields, meltingData, 'Audit-Report');
        };

        const exportExcel = () => {
            const tableHeader = columns.map((col) => ({
                header: col.label,
                key: col.id,
                group: col.group,
            }));
            exportToExcel(tableHeader, meltingData, 'Melting-Report');
        };

        return (
            <Stack direction="row" spacing={1}>
                <LoadingButton onClick={exportPDF} variant="outlined" startIcon={<FileDownloadOutlined />}>
                    PDF
                </LoadingButton>
                <LoadingButton
                    onClick={exportExcel}
                    variant="outlined"
                    startIcon={<FileDownloadOutlined />}
                >
                    Excel
                </LoadingButton>
            </Stack>
        );
    };

    // Event Handlers
    const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearch = (query: string) => {
        setSearchQuery(query);
    };

    const handleDateChange = (startDateVal: string, endDateVal: string) => {
        setStartDate(startDateVal);
        setEndDate(endDateVal);
    };

    return (
        <div>
            <FilterComponent
                onDateChange={handleDateChange}
                onSearch={handleSearch}
                searchResultCount={totalRecords}
                disableTransactionType
                dayRange={1}
                action={<Actions />}
                searchPlaceholder='Search'
            />
            {loading ? (
                <TableSkeleton />
            ) : (
                <GenericTable
                    columns={columns}
                    rows={meltingData}
                    totalRecords={totalRecords}
                    page={page}
                    pageSize={pageSize}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    loading={loading}
                />
            )}
            <Divider />
        </div>
    );
};

export default MeltingReport;
