import { format } from 'date-fns';
import { toast } from 'react-hot-toast';
import React, { useState, useEffect } from 'react';

// store
import { useDispatch, useSelector } from 'react-redux';

// Form Handling
import { useForm, FormProvider, useWatch } from 'react-hook-form';

// Route
import { useParams, useNavigate } from 'react-router-dom';

// MUI Components
import { Box, Grid, Button, Divider, Typography, CircularProgress } from '@mui/material';

// Utils
import { restrictToTwoDecimals } from 'src/utils/purity-calculation';

import { RootState, AppDispatch } from 'src/store/store';
import { updateMeltingSummary, fetchMeltingSummaryRecord } from 'src/store/slices/meltings-slice';

// Custom Components
import { RHFTextField } from 'src/components/hook-form';
import { DynamicDetails } from 'src/components/customer-card';
import CustomBreadcrumbs from 'src/components/custom-breadcrumb/custom-breadcrumb';

// Validation
import { yupResolver } from '@hookform/resolvers/yup';
import meltingSummarySchema from '../components/schema/melting-summary-schema';

const UpdateMeltingSummary: React.FC = () => {
  const { orderid } = useParams<{ orderid: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { currentTransaction } = useSelector((store: RootState) => store.melting);

  const [loading, setLoading] = useState(true);
  const [totalDeduction, setTotalDeduction] = useState(0);
  const [totalCollection, setTotalCollection] = useState(0);
  const [difference, setDifference] = useState(0);

  const methods = useForm({
    resolver: yupResolver(meltingSummarySchema),
    defaultValues: {
      crushed_stone: 0,
      whole_stone: 0,
      melting_waste: 0,
      phy_melting_waste: 0,
      net_gain: 0,
      am_weight: 0,
    },
  });
  const { handleSubmit, reset, setValue, getValues, formState } = methods;
  const { isDirty } = formState;

  const watchedFields = useWatch({
    control: methods.control,
    name: ['crushed_stone', 'whole_stone', 'melting_waste', 'phy_melting_waste', 'net_gain'],
  });

  // Initialize form values from currentTransaction
  useEffect(() => {
    if (currentTransaction) {
      setFormValues(currentTransaction);
    }
  }, [currentTransaction]);

  // Function to set form values
  const setFormValues = (transaction: any) => {
    const {
      crushed_stone_weight = 0,
      whole_stone_weight = 0,
      melting_waste = 0,
      physical_melting_waste = 0,
      net_weight = 0,
      am_weight = 0,
      total_deduction = 0,
    } = transaction;

    setValue('crushed_stone', crushed_stone_weight);
    setValue('whole_stone', whole_stone_weight);
    setValue('melting_waste', melting_waste);
    setValue('phy_melting_waste', physical_melting_waste);
    setValue('net_gain', net_weight);
    setValue('am_weight', am_weight);

    setTotalDeduction(total_deduction);
    setLoading(false);
  };

  useEffect(() => {
    const fetchTransaction = async () => {
      try {
        setLoading(true);
        await dispatch(fetchMeltingSummaryRecord(orderid!)).unwrap();
      } catch (error) {
        toast.error('Failed to load transaction details');
        navigate('/404');
      }
    };

    if (orderid) {
      fetchTransaction();
    }
  }, [orderid, navigate]);

  useEffect(() => {
    const calculateTotals = () => {
      if (!watchedFields) return;

      const [crushedStone, wholeStone, meltingWaste, phyMeltingWaste, netGain] = watchedFields.map(Number);
      const total = crushedStone + wholeStone + meltingWaste + phyMeltingWaste + netGain;

      setTotalCollection(total);
      setDifference(total - totalDeduction);
    };

    calculateTotals();
  }, [watchedFields, totalDeduction]);

  const onSubmit = async (formData: any) => {
    try {
      const meltingData = {
        crushed_stone_weight: Number(formData.crushed_stone),
        whole_stone_weight: Number(formData.whole_stone),
        physical_melting_waste: Number(formData.melting_waste),
        melting_waste: Number(formData.phy_melting_waste),
        net_gain: Number(formData.net_gain),
        am_weight: Number(formData.am_weight),
      };

      await dispatch(updateMeltingSummary({ id: orderid!, data: meltingData })).unwrap();
      toast.success('Melting summary updated successfully');
      navigate(-1);
    } catch (error: any) {
      toast.error(error);
    }
  };

  const onCancel = () => {
    if (currentTransaction) {
      setFormValues(currentTransaction);
      methods.reset(getValues()); // Reset the form and mark it as not dirty
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div>
      <CustomBreadcrumbs
        heading={`Melting Summary of ${format(new Date(), 'dd-MM-yyyy')}`}
        links={[
          { name: 'Dashboard', href: '/' },
          { name: 'Melting Summary', href: `/dashboard/melting/summary` },
        ]}
        sx={{ mb: { xs: 3 } }}
      />

      <Grid container mb={1} spacing={3} mt={1}>
        <Grid item xs={12} md={6}>
          <DynamicDetails
            title="Billing Details"
            details={{
              'Gross Weight': currentTransaction!.gross_weight.toFixed(2),
              'Stone Deduction': currentTransaction!.stone_deduction.toFixed(2),
              'Wastage Deduction': currentTransaction!.wastage_deduction.toFixed(2),
              'Net Weight': currentTransaction!.net_weight.toFixed(2),
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DynamicDetails
            title="Calculation"
            details={{
              'Total Deduction': totalDeduction.toFixed(2),
              'Total Collection': totalCollection.toFixed(2),
              'Difference': difference.toFixed(2),
            }}
          />
        </Grid>
      </Grid>

      <Box bgcolor="white" p={3} mt={3}>
        <Typography variant="h6" mb={2}>
          Processing Details
        </Typography>
        <Divider sx={{ mb: 5 }} />

        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              {[
                { name: 'crushed_stone', label: 'Crushed Stone (gms)' },
                { name: 'whole_stone', label: 'Whole Stone (gms)' },
                { name: 'melting_waste', label: 'Melting Waste (gms)' },
                { name: 'phy_melting_waste', label: 'Phy Melting Waste (gms)' },
                { name: 'am_weight', label: 'AM Weight (gms)' },
                { name: 'net_gain', label: 'Net Gain (gms)' },
              ].map(({ name, label }) => (
                <Grid item xs={6} md={4} key={name}>
                  <RHFTextField
                    name={name}
                    label={label}
                    type="number"
                    inputProps={{
                      onInput: restrictToTwoDecimals,
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              ))}
            </Grid>

            {isDirty && (
              <Box mt={4} display="flex" justifyContent="flex-start" gap={2}>
                <Button variant="outlined" onClick={onCancel}>
                  Cancel
                </Button>
                <Button variant="contained" color="primary" type="submit">
                  Save
                </Button>
              </Box>
            )}
          </form>
        </FormProvider>
      </Box>

    </div>
  );
};

export default UpdateMeltingSummary;
