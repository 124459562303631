// @ts-nocheck
/* eslint-disable */
import { KycCustomerValues } from 'src/interfaces';

import API from '..';
import { kyc, transaction } from '../entpoints';

export const valIdProof = async (
  document_class: string,
  document_type: string,
  id: string = null,
  document_id: string,
  transaction_id: string,
  front_image: File,
  back_image?: File,
  client_id?: string,
  otp?: string,
  dob?: string
) => {
  try {
    const formData = new FormData();
    formData.append('document_class', document_class);
    formData.append('document_type', document_type);
    if (id) {
      formData.append('id', id);
    }
    formData.append('document_id', document_id);
    formData.append('transaction_id', transaction_id);
    formData.append('front_image', front_image);
    formData.append('back_image', back_image);
    formData.append('client_id', client_id);
    formData.append('otp', otp);
    formData.append('dob', dob);

    const resp = await API.post(kyc.validateIdProof, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

/* eslint-disable no-prototype-builtins, no-restricted-syntax */
export const customerDetails = async (customerData: KycCustomerValues) => {
  try {
    const formData = new FormData();

    // Append customer data
    for (const key in customerData.customer) {
      if (customerData.customer.hasOwnProperty(key)) {
        formData.append(`customer[${key}]`, (customerData.customer as any)[key]);
      }
    }

    customerData.documents.forEach((document, index) => {
      if (!document.front_image && document.document_type !== 'CONTACT') {
        return;
      }
      Object.entries(document).forEach(([key, value]) => {
        formData.append(`documents[${index}][${key}]`, value);
      });
    });

    formData.append('transaction_id', customerData.transaction_id);

    const resp = await API.post(`${kyc.postCustomerDetails}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const UpdateCustomerDetails = async (id: string, customerData: KycCustomerValues) => {
  try {
    const formData = new FormData();

    for (const key in customerData.customer) {
      if (customerData.customer.hasOwnProperty(key)) {
        if (key == 'image' && typeof customerData.customer[key] == 'string') {
          continue;
        }
        formData.append(`customer[${key}]`, (customerData.customer as any)[key]);
      }
    }

    for (const key in customerData.jewellary) {
      if (customerData.jewellary.hasOwnProperty(key)) {
        formData.append(`documents[0][${key}]`, (customerData.jewellary as any)[key]);
      }
    }

    // Append bill data if available
    if (customerData.bill) {
      for (const key in customerData.bill) {
        if (customerData.bill.hasOwnProperty(key)) {
          formData.append(`documents[1][${key}]`, (customerData.bill as any)[key]);
        }
      }
    }

    const resp = await API.patch(`${kyc.updateCustomerKyc}/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getDocumentsById = async (id: string) => {
  try {
    const resp = await API.get(`${kyc.getDocuments}/${id}`);
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getKycId = async (transactionId: string) => {
  try {
    const resp = await API.get(`${transaction.index}/${transactionId}`);
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getKycImages = async (kycId: string) => {
  try {
    const resp = await API.get(`${kyc.getKycDetails}/${kycId}`);
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getKycPendingDocs = async (kycId: string) => {
  try {
    const resp = await API.get(`${kyc.getKycDetails}/${kycId}/pending_documents`);
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const makerApproval = async (
  kycId: string,
  data: IFormValues,
  remark: string,
  rejected: boolean,
  file: File | null,
  additionalInfoRef: React.RefObject<any>
) => {
  try {
    const formData = new FormData();
    formData.append('maker_remark', remark);
    formData.append('rejected', rejected ? 'true' : 'false');

    // Append checklist items
    data.checklist
      .filter((item) => item.answer !== null)
      .forEach((item, index) => {
        formData.append(`checklists[${index}][id]`, item.id);
        formData.append(`checklists[${index}][answer]`, item.answer ? 'true' : 'false');
      });

    // Append file if provided
    if (file) {
      formData.append('call_recording', file);
    }

    // Append additional info files if available
    if (additionalInfoRef.current) {
      const { audioFile, imageFile, documentId } = additionalInfoRef.current.getUploadedFiles();

      if (documentId) {
        formData.append('document[id]', documentId);
      }
      if (imageFile) {
        formData.append('document[front_image]', imageFile);
      }
      if (audioFile) {
        formData.append('document[contact_recording]', audioFile);
      }
    }

    const resp = await API.patch(`${kyc.getKycDetails}/${kycId}/maker_approvals`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return resp;
  } catch (error) {
    throw new Error(error.response?.data?.message);
  }
};

export const requestDocuments = async (kycId: string, payload: any) => {
  try {
    const resp = await API.post(`${kyc.getKycDetails}/${kycId}/request_documents`, payload);
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const checkerApproval = async (kycId: string, payload: any) => {
  try {
    const resp = await API.patch(`${kyc.getKycDetails}/${kycId}/checker_approvals`, payload);
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const callCustomer = async (kycId: string, payload: any) => {
  try {
    const resp = await API.post(`${kyc.getKycDetails}/call_customer`, payload);
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getKycRecording = async (kycId: string) => {
  try {
    const resp = await API.post(`${kyc.getKycDetails}/${kycId}`);
    return resp;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getKycTransactions = async ({
  status,
  pageSize = 10,
  page = 1,
  startDate = '',
  endDate = '',
  searchText = '',
  transactionType = '',
}: {
  status: any;
  pageSize?: number;
  page?: number;
  startDate?: string;
  endDate?: string;
  searchText?: string;
  transactionType?: string;
}) => {
  try {
    const resp = await API.get(
      `${kyc.getKycDetails}?status=${status}&page_size=${pageSize}&page_number=${page}&start_date=${startDate}&end_date=${endDate}&search_text=${searchText}&transaction_type=${transactionType}`
    );
    return resp;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || 'An error occurred while fetching KYC transactions.'
    );
  }
};

export const outBoundCall = async (kyc_id, reference_contact_number, document_id) => {
  const body = {
    id: kyc_id,
    customer_number: reference_contact_number,
    document_id: document_id,
  };
  try {
    const resp = await API.post(`${kyc.callCustomer}`, body);
    return resp;
  } catch (error) {
    throw new Error(error.response?.data?.message);
  }
};
