import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { BidData, BidState } from 'src/interfaces';
import {
  getBids,
  createBidDetails,
  updateBidDetails,
  deleteBidDetails,
  getBidsSum,
} from 'src/apis/bids';

const initialState: BidState = {
  loading: false,
  error: undefined,
  currentPage: 1,
  currentPageSize: 10,
  totalRecords: 0,
  records: [],
  totalBidSum: 0, // New field for storing the sum of bids
};

// Fetch bids sum
export const fetchBidsSum = createAsyncThunk(
  'bids/fetchBidsSum',
  async ({ startDate, endDate }: { startDate?: string; endDate?: string }) => {
    const response = await getBidsSum(startDate || '', endDate || '');
    return response; // Assuming the API returns the sum in `data`
  }
);

// Fetch all bids
export const fetchBids = createAsyncThunk(
  'bids/fetchBids',
  async ({
    status,
    pageSize,
    page,
    startDate,
    endDate,
    searchText,
    transactionType,
  }: {
    status?: string;
    pageSize?: number;
    page?: number;
    startDate?: string;
    endDate?: string;
    searchText?: string;
    transactionType?: string;
  }) => {
    const bids = await getBids(
      status || '',
      pageSize,
      page,
      startDate,
      endDate,
      searchText,
      transactionType
    );
    return bids;
  }
);

// Create a new bid
export const createBid = createAsyncThunk(
  'bids/createBid',
  async (formData: BidData) => {
    const newBid = await createBidDetails(formData);
    return newBid;
  }
);

// Update an existing bid
export const updateBid = createAsyncThunk(
  'bids/updateBid',
  async ({ bidId, formData }: { bidId: string; formData: any }) => {
    const updatedBid = await updateBidDetails(bidId, formData);
    return updatedBid;
  }
);

// Delete a bid
export const deleteBid = createAsyncThunk(
  'bids/deleteBid',
  async (bidId: string) => {
    await deleteBidDetails(bidId);
    return bidId;
  }
);

const bidSlice = createSlice({
  name: 'Bids',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Fetch bids sum
    builder.addCase(fetchBidsSum.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(fetchBidsSum.rejected, (state, action) => {
      state.error = action.error?.message;
      state.loading = false;
    });
    builder.addCase(fetchBidsSum.fulfilled, (state, action) => {
      state.totalBidSum = action.payload || 0; // Store the bid sum
      state.loading = false;
    });

    // Fetch bids
    builder.addCase(fetchBids.pending, (state) => {
      state.loading = true;
      state.error = undefined; // Reset error state
    });
    builder.addCase(fetchBids.rejected, (state, action) => {
      state.error = action.error?.message;
      state.loading = false;
    });
    builder.addCase(fetchBids.fulfilled, (state, action) => {
      state.records = action.payload.records || [];
      state.currentPage = action.payload.current_page || 1;
      state.totalRecords = action.payload.total_records || 0;
      state.loading = false;
    });

    // Create bid
    builder.addCase(createBid.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createBid.rejected, (state, action) => {
      state.error = action.error?.message;
      state.loading = false;
    });
    builder.addCase(createBid.fulfilled, (state, action) => {
      state.records.unshift(action.payload); // Add new bid
      state.totalRecords += 1; // Increment total
      state.loading = false;
    });

    // Update bid
    builder.addCase(updateBid.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateBid.rejected, (state, action) => {
      state.error = action.error?.message;
      state.loading = false;
    });
    builder.addCase(updateBid.fulfilled, (state, action) => {
      const index = state.records.findIndex((bid) => bid.id === action.payload.id);
      if (index >= 0) {
        state.records[index] = action.payload; // Update bid
      }
      state.loading = false;
    });

    // Delete bid
    builder.addCase(deleteBid.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteBid.rejected, (state, action) => {
      state.error = action.error?.message;
      state.loading = false;
    });
    builder.addCase(deleteBid.fulfilled, (state, action) => {
      state.records = state.records.filter((bid) => bid.id !== action.payload);
      state.totalRecords -= 1;
      state.loading = false;
    });
  },
});

export default bidSlice.reducer;
